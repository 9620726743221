body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
[id^="rbd-announcement"] {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    border: 0px;
    padding: 0px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(100%);
}
