.App {
    text-align: center;
}

.json-highlight {
    overflow-wrap: break-word;
    font-size: 0.8em;
    line-height: 20px;
    .string {
        color: green;
    }

    .number {
        color: darkorange;
    }

    .boolean {
        color: blue;
    }

    .null {
        color: red;
    }

    .key {
        color: #252b35;
    }
}
.shepherd-header {
    background: white !important;
    padding: 16px 24px !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
    line-height: 1.25rem !important;
    color: rgba(0, 0, 0, 0.85) !important;
}
.shepherd-arrow:before {
    background-color: white !important;
}
.shepherd-text {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 0.875rem !important;
    line-height: 1.43em !important;
    padding: 0 24px 16px !important;
}
.shepherd-cancel-icon {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
}
